<template>
  <b-modal
    v-model="localActive"
    id="modal-editar-tarea"
    hide-footer
    centered
    title="Editar partida"
  >
    <form @submit.prevent="editarTarea()">
      <div class="form-row">
        <div class="col-md-12">
          <label for="descripcion">Descripción</label>
          <textarea
            class="form-control"
            name="descripcion"
            id="descripcion"
            v-model="descripcion"
            cols="30"
            rows="10"
          ></textarea>
        </div>
      </div>

      <div class="form-row mt-2">
        <div class="col-md-6">
          <label for="unidadMedida">Unidad de medida</label>
          <input type="text" class="form-control" v-model="unidadMedida" />
        </div>

        <div class="col-md-6">
          <label for="cantidad">Cantidad</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="cantidad"
            pattern="^[0-9]*$"
            @keypress="regexNumber"
          />
        </div>
      </div>

      <div class="form-row mt-2">
        <div class="col-md-12">
          <label for="horario">Asignar horario</label>
          <select id="horario" class="form-control" v-model="horario">
            <option value="0">Diurno</option>
            <option value="1">Nocturno</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="observacion">Observación</label>
        <textarea
          id="observacion"
          class="form-control"
          v-model="observacion"
        ></textarea>
      </div>

      <div class="text-right mt-3">
        <button
          :disabled="loading"
          type="button"
          class="btn btn-link"
          @click="localActive = false"
        >
          Volver
        </button>

        <button
          type="submit"
          class="btn btn-warning"
          :disabled="$v.$invalid || loading"
        >
          <template v-if="loading">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>

            <span class="d-inline-block ml-2">Editando</span>
          </template>

          <span v-else>Editar Partida</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";

import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import UpdateTareasSupervisor from "@/apollo/mutations/tareas/UpdateTareasSupervisor.gql";

export default {
  mixins: [validationMixin],
  directives: { hideAdmin, hideContratante, hideSupervisor },
  data() {
    return {
      descripcion: "",
      observacion: "",
      unidadMedida: null,
      cantidad: null,
      horario: "0",
      precioSupervisor: null,
      loading: false,
      lockPrecioSupervisor: false,
    };
  },

  validations: {
    descripcion: { required },
    observacion: { required },
    unidadMedida: { required },
    cantidad: { required },
    horario: { required },
  },

  props: {
    codigoTicket: {
      type: String,
    },
    active: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },

  methods: {
    regexNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    setData() {
      let {
        cantidad,
        horario,
        observacion,
        precioSupervisor,
        descripcion,
        unidadMedida,
      } = this.data;

      this.descripcion = descripcion;
      this.unidadMedida = unidadMedida;
      this.cantidad = cantidad;
      this.precioSupervisor = precioSupervisor;
      this.horario = horario;
      this.observacion = observacion;

      this.loading = false;
    },

    editarTarea() {
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;

      let {
        horario,
        observacion,
        unidadMedida,
        cantidad,
        precioSupervisor,
        descripcion,
      } = this;

      this.$apollo
        .mutate({
          mutation: UpdateTareasSupervisor,
          errorPolicy: "all",
          variables: {
            input: [
              {
                tareaId: this.data.tareaId,
                horario: parseInt(horario),
                observacion,
                unidadMedida,
                cantidad: parseInt(cantidad),
                precioSupervisor: parseFloat(precioSupervisor),
                descripcion,
                codigoTicket: this.codigoTicket,
              },
            ],
          },
        })
        .then((res) => {
          if (res.errors) {
            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` La partida no se ha podido actualizar, intentelo nuevamente `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Tarea no actualizada")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
          }

          this.$emit("success");

          this.loading = false;

          this.$bvModal.hide("modal-editar-tarea");

          this.$toast.success(`Tarea Actualizada`, {
            // override the global option
            position: "top-right",
          });

          this.limpiarCampos();
          this.lockPrecioSupervisor = false;
        })
        .catch(() => {
          this.loading = false;

          this.$toast.error("Ocurrió un error, inténtelo nuevamente", {
            // override the global option
            position: "top-right",
          });
          // this.lockPrecioSupervisor = false;
        });
    },
    limpiarCampos() {
      this.descripcion = "";
      this.observacion = "";
      this.unidadMedida = null;
      this.cantidad = null;
      this.horario = "0";
      this.precioSupervisor = null;
    },
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),

    localActive: {
      get() {
        if (this.active) {
          this.setData();
        }
        return this.active;
      },
      set(val) {
        this.$emit("close");
        if (!val) {
          this.limpiarCampos();
        }
      },
    },
  },
};
</script>

<style lang="scss"></style>
