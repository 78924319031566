<template>
  <LayoutView
    :titulo="`Detalle de asignación #${id}`"
    :fondo="asignacion && asignacion ? asignacion.local.imagen : ''"
    :seccionDetalle="`${estados[1].value}. Estado ${estados[1].title} `"
  >
    <!-- <p v-hide-supervisor>AAAA</p> -->
    <form-wizard
      ref="wizard"
      stepSize="sm"
      color="var(--dark)"
      error-color="var(--dark)"
      title=""
      subtitle=""
      back-button-text="Retroceder"
      next-button-text="Siguiente"
      finish-button-text="Actualizar Asignación"
      @on-complete="openModal"
    >
      <!-- GEOLOCALIZACION -->
      <tab-content title="Geolocalización" :before-change="geolocate">
        <!-- INGRESO DE LA GEOLOCALIZACIÓN -->
        <template
          v-if="asignacion && !asignacion.longitud && !asignacion.latitud"
        >
          <!-- POR SI EL SUPERVISOR NO HA IDO -->
          <!-- VISTA PARA EL ADMINISTRADOR Y CONTRATISTA -->
          <template
            v-if="
              datosUsuarioLogueado.tipoUsuario === 1 ||
                datosUsuarioLogueado.tipoUsuario === 3
            "
          >
            <b-alert variant="danger" show
              >El supervisor aún no ha realizado reconocimiento de
              local.</b-alert
            >
          </template>

          <!-- POR SI EL SUPERVISOR NO HA IDO -->
          <!-- VISTA PARA EL SUPERVISOR -->
          <template v-if="datosUsuarioLogueado.tipoUsuario === 2">
            <b-jumbotron>
              <template #lead class="text-center">
                <div class="d-flex text-center justify-content-center">
                  <i class="fas fa-map-marker-alt fa-10x"></i>
                </div>
                <hr class="my-3" />
                <p class="h5 text-center font-italic mt-5">
                  Para iniciar primero debe brindar los permisos de ubicación
                </p>
              </template>
            </b-jumbotron>
          </template>
        </template>

        <b-alert
          v-if="asignacion && asignacion.longitud && asignacion.latitud"
          variant="success"
          show
          >Ya fue grabado la ubicación, por favor continua</b-alert
        >
      </tab-content>

      <!-- INGRESAR TAREAS -->
      <tab-content title="Ingresar partidas" :before-change="checkTareas">
        <PrimerTab ref="step1" v-if="asignacion"></PrimerTab>
      </tab-content>

      <!-- INGRESAR FOTO DE VISITA -->
      <tab-content title="Ingresar foto de visita">
        <section class="row mt-3 mb-3">
          <div class="col-12">
            <vue-dropzone
              ref="dropzoneImage"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-complete="setImageURLPreview"
            ></vue-dropzone>
          </div>
          <hr />
          <div class="col-12 galeria-previa mt-3" v-if="fotoFichaVisita">
            <span
              class="galeria-previa__icono d-flex justify-content-center align-items-center pointer"
              @click="removeImage(index)"
            >
              <i class="fas fa-trash-alt"></i>
            </span>

            <img
              :src="fotoFichaVisita.urlPreview"
              alt=""
              class="img-fluid galeria-previa__imagen"
            />

            <div class="form-group mt-2">
              <label for="description">Descripción</label>
              <textarea
                id="description"
                class="form-control"
                required
                placeholder="Descripción sobre la imagen"
                v-model="fotoFichaVisita.descripcion"
              ></textarea>
            </div>
          </div>
        </section>
      </tab-content>
    </form-wizard>

    <b-modal
      id="modal-corroboracion"
      hide-footer
      center
      title="¿Desea confirma su acción?"
    >
      <p class="h5 text-center">
        Por favor confirme su acción de ingresar partidas y si posee ingrese una
        foto de visita
      </p>
      <p class="h6 font-italic text-center mt-3">
        Si confirma no podrá crear otras partidas y si no ha ingresado foto de
        visita no podrá hacerlo luego.
      </p>
      <b-button
        :disabled="loading"
        @click="submitData"
        class="mt-3"
        block
        variant="warning"
      >
        <template v-if="loading">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only">Actualizando...</span>

          <span class="d-inline-block ml-2">Actualizando</span>
        </template>

        <span v-else>Actualizar esta asignación</span>
      </b-button>
    </b-modal>
  </LayoutView>
</template>

<script>
import { mapState } from "vuex";
import { estados } from "../../../../.env";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";

import CreateImage from "@/apollo/mutations/imagenes/CreateImage.gql";

import LayoutView from "@/layouts/detalles.vue";

import estadoMixins from "@/mixins/estadoMixins.js";
import baseReconocimiento from "@/mixins/baseReconocimiento.js";

import PrimerTab from "@/components/asignaciones/terceraVista/primerTab.vue";

import { hideAdmin, hideContratante } from "@/directives/roleDirective.js";

export default {
  components: { LayoutView, PrimerTab },
  directives: { hideAdmin, hideContratante },
  mixins: [estadoMixins, baseReconocimiento],
  data() {
    return {
      id: this.$route.params.id,
      estados: estados,
      loading: false,
      asignacion: null,
      fotoFichaVisita: null,
      searchingUbication: false,
      ubicationError: false,
      ubicationErrorMessage: "",
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        maxFiles: 1,
        thumbnailWidth: 145,
        thumbnailHeight: 145,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastre o haga click aquí</p>
                              <p class="form-text">Archivos permitidos: .jpg, .jpeg, .png</p> <p class="form-text font-italic">Solo puede subir 1 imagen.</p>
                            `,
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
  methods: {
    geolocate() {
      if (this.datosUsuarioLogueado.tipoUsuario === 2) {
        this.searchingUbication = true;
        this.ubicationError = false;

        return new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            this.setGeolocalizacion,
            this.errorUbicationFunction
          );

          resolve();
          return true;
        });
      } else {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Debe esperar a que el supervisor finalice el reconocimiento del local. `,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);

        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "No puede continuar")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
        return false;
      }
    },
    setGeolocalizacion(position) {
      this.asignacion.latitud = position.coords.latitude;
      this.asignacion.longitud = position.coords.longitude;

      this.searchingUbication = false;
      this.$refs.wizard.changeTab(0, 1);
    },
    errorUbicationFunction() {
      const h = this.$createElement;

      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        ` Debes permitir la ubicación, intentelo nuevamente, brinde los permisos. `,
        h("b-spinner", { props: { type: "grow", small: true } }),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        {
          class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
        },
        [h("strong", { class: "mr-2" }, "Ubicación no brindada")]
      );

      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: "danger",
      });

      this.searchingUbication = false;
    },
    openModal() {
      // if (!this.checkImageContent()) return;

      this.$bvModal.show("modal-corroboracion");
    },
    checkImageContent() {
      if (
        this.fotoFichaVisita &&
        this.fotoFichaVisita.imagen &&
        this.fotoFichaVisita.urlPreview &&
        this.fotoFichaVisita.descripcion
      ) {
        return true;
      }

      const h = this.$createElement;

      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        ` La información de la imagen esta incompleta, verifique que la imagen tenga descripción. `,
        h("b-spinner", { props: { type: "grow", small: true } }),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        {
          class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
        },
        [
          h(
            "strong",
            { class: "mr-2" },
            "La descripción de la imagen esta incompleta"
          ),
        ]
      );

      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: "danger",
      });

      return false;
    },

    privateSubmitData() {
      // SETEO DE LA FECHA DE INICIO ORIGINAL
      const actualDate = new Date().toISOString().slice(0, 10);
      this.asignacion.fechaInicioOriginal = actualDate;

      this.updateReconocimientoLocal(this.asignacion, this.id).then(
        async (res) => {
          // SI HAY ERROR
          if (res.errors) {
            this.loading = false;

            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` La asignación no se ha podido actualizar, intentelo nuevamente `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Asignación no actualizada")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
          }
          // SI TODO SALE BIEN EN GENERAL
          // DEBEMOS MANDARLO A LA TERCERA VISTA
          else {
            this.loading = false;

            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { variant: "success", small: true } }),
              ` Asignación #${this.id} actualizada, espere a que el contratista de los precios respectivos `,
              h("b-spinner", { props: { variant: "success", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Asignación actualizada")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "success",
            });

            // NO TOCAR ESTA PARTE POR NADA DEL MUNDO, SUPER PELIGROSO SI SE TOCA
            const actualDate = new Date().toISOString().slice(0, 10);
            this.asignacion.fechaInicioOriginal = actualDate;

            await this.privateActualizarEstadoAsignacion(3);

            this.$router.push({
              name: "asignacion-detalle-tercero",
              params: { id: this.id },
            });
          }
        }
      );
    },

    submitData() {
      this.loading = true;
      if (this.fotoFichaVisita && this.fotoFichaVisita.imagen) {
        this.privateUploadImages().then(() => {
          this.privateSubmitData();
        });
      } else {
        this.asignacion.fotoFichaVisita = null;
        this.privateSubmitData();
      }
    },
    privateUploadImages() {
      return new Promise((resolveFunction) => {
        this.$apollo
          .mutate({
            mutation: CreateImage,
            errorPolicy: "all",
            variables: {
              imagen: this.fotoFichaVisita.imagen,
              nombre: this.fotoFichaVisita.descripcion,
            },
          })
          .then((resImage) => {
            if (resImage.errors) {
              this.loading = false;
              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` La imagen no se pudo subir o la información esta incompleta, verifique que la imagen tenga descripción. `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "La imagen no se pudo subir")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
              });
              return;
            }

            // SI LO SUBE CORRECTAMENTE
            this.asignacion.fotoFichaVisita = resImage.data.CreateImage.id;

            resolveFunction();
          });
      });
    },
    init() {
      this.$apollo
        .query({
          query: GetAsignaciones,
          variables: {
            codigoTicket: this.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.asignacion = response.data.GetAsignaciones;
        });
    },
    setImageURLPreview(file) {
      let archivo = {
        imagen: file,
        urlPreview: URL.createObjectURL(file),
        descripcion: "",
      };

      this.fotoFichaVisita = { ...archivo };

      this.$refs.dropzoneImage.removeFile(file);
    },
    removeImage() {
      this.fotoFichaVisita = null;
    },
    checkTareas() {
      let isValid = this.$refs.step1.tareas.length > 0;
      if (!isValid) {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Para poder ingresar la foto de la visita se debe por lo menos agregar una partida. `,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "Agregue al menos 1 partida")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
      }
      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.galeria-previa {
  &__imagen {
    width: 100%;
    margin: 0 auto;
    border-radius: 0.3rem;
  }

  &__icono {
    font-size: 25px;
    color: red;

    background-color: white;
    padding: 10px;
    border-radius: 50%;
    -webkit-box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    box-shadow: -16px 22px 56px -18px rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    right: 0;
  }

  textarea {
    height: 60px !important;
  }
}

@media (min-width: 768px) {
  .galeria-previa {
    &__icono {
      margin: 20px 20px 0 0;
      right: 1rem;
    }
  }
}
</style>
