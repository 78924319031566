<template>
  <div class="row" v-if="asignacion">
    <div class="col">
      <!-- Si ya existe reconocimiento de local -->
      <template>
        <div class="d-flex justify-content-between align-items-center">
          <p class="h5 detalles-asignacion__sub font-italic my-0">
            Partidas registradas
          </p>

          <button
            type="button"
            v-hide-admin
            v-hide-contratante
            class="btn btn-success btn-sm"
            @click="$bvModal.show('modal-crear-tarea')"
            v-if="allowAddTareas"
          >
            <i class="fas fa-plus mr-2"></i>
            Registrar Partidas
          </button>
        </div>

        <hr />

        <p class="small mt-1 text-center font-italic" v-if="loading">
          <template>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>

            <span class="d-inline-block ml-2">Obteniendo Partidas</span>
          </template>
        </p>

        <!-- En caso de tener TAREAS -->
        <!-- MUESTRA DE TAREAS ASIGNADAS -->
        <section class="mt-3" v-else>
          <div class="row" v-if="tareas.length">
            <div
              class="col-md-12 mb-3"
              v-for="tarea in tareas"
              :key="tarea.tareaId"
            >
              <!-- Muestra un card con las tareas, cada card es collapse y muestra info detallada de cada tarea -->
              <b-card class="bg-info">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <a
                      class="text-light"
                      href=""
                      v-b-toggle="tarea.tareaId"
                      @click.prevent
                      variant="info"
                      >{{ tarea.descripcion }}</a
                    >
                  </div>

                  <!-- BOTONES DE ACCION -->
                  <div class="d-flex">
                    <button
                      v-hide-admin
                      v-hide-contratante
                      @click="privateOpenEditTarea(tarea)"
                      type="button"
                      class="btn btn-warning btn-sm mr-4"
                      v-b-tooltip.hover
                      title="Editar Partida"
                    >
                      <i class="fas fa-marker"></i>
                    </button>
                    <button
                      v-hide-admin
                      v-hide-contratante
                      @click="eliminarTareaRegistrada(tarea.tareaId)"
                      type="button"
                      class="btn btn-danger btn-sm"
                      v-b-tooltip.hover
                      title="Eliminar Tarea"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>

                <b-collapse :id="tarea.tareaId" class="mt-2">
                  <hr />

                  <b-card>
                    <p>
                      <i class="fas fa-calendar-alt"></i>
                      {{ tarea.created_at | moment("DD/MM/YYYY") }}
                    </p>

                    <small class="text-muted">Observaciones:</small>

                    <p class="card-text my-1 pb-1 border-bottom">
                      {{ tarea.observacion }}
                    </p>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Descripción</span>
                          <span class="ml-2">
                            {{ tarea.descripcion }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Horario asignado:</span
                          >
                          <span class="ml-2">{{
                            tarea.horario === 0 ? "Diurno" : "Nocturno"
                          }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <p class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted">Medidas:</span>
                          <span class="ml-2"
                            >{{ tarea.cantidad }} ({{
                              tarea.unidadMedida
                            }})</span
                          >
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div
                        class="col-md-4"
                        v-hide-supervisor
                        v-hide-contratante
                      >
                        <div class="my-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Precio administrador:</span
                          >

                          <br />

                          <div class="contenedor-precio">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"
                                  >S/</span
                                >
                              </div>

                              <input
                                type="number"
                                step="0.01"
                                class="form-control"
                                placeholder="Asignar precio"
                                v-model="tarea.precioAdministrador"
                              />
                            </div>
                          </div>

                          <span class="small font-weight-bold"
                            >Subtotal:
                            {{
                              formatoAPrecio(
                                setSubtotal(
                                  tarea.precioAdministrador,
                                  tarea.cantidad
                                )
                              )
                            }}</span
                          >
                          <div>
                            <!-- <div class="text-right mt-1">
                                      <button type="button" class="btn btn-primary btn-sm" :disabled="actualizandoPrecioAdmin || !tarea.precioAdministrador" @click="asignarPrecioAdministrador">{{ actualizandoPrecioAdmin ? 'Actualizando...' : 'Asignar' }}</button>
                                    </div> -->
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-4" v-hide-contratante>
                        <div class="mt-2 border-bottom">
                          <div>
                            <span class="small text-muted"
                              >Precio
                              {{ esAdmin ? "Supervisor" : "Asignado" }}:</span
                            >
                            <br />
                            <span class="contenedor-precio">{{
                              formatoAPrecio(tarea.precioSupervisor)
                            }}</span>
                          </div>

                          <span class="small font-weight-bold"
                            >Subtotal:
                            {{
                              formatoAPrecio(
                                setSubtotal(
                                  tarea.precioSupervisor,
                                  tarea.cantidad
                                )
                              )
                            }}</span
                          >
                        </div>
                      </div>

                      <div class="col-md-4" v-if="esAdmin || esContratista">
                        <div class="my-1 pb-1 border-bottom">
                          <div>
                            <span class="small text-muted"
                              >{{
                                esAdmin
                                  ? "Precio contratista"
                                  : "Asignar precio (Contratista) (S/.)"
                              }}:</span
                            >
                            <br />
                            <span class="contenedor-precio" v-if="esAdmin">{{
                              tarea.copiaPrecioContratista
                                ? ` ${formatoAPrecio(
                                    tarea.copiaPrecioContratista
                                  )}`
                                : "Por asignar"
                            }}</span>

                            <input
                              type="number"
                              step="0.01"
                              class="form-control mt-1"
                              placeholder="Asignar precio (Contratista)"
                              required
                              @keypress="regexNumber"
                              v-model="tarea.precioContratista"
                              v-if="esContratista"
                            />
                          </div>

                          <div>
                            <span class="small font-weight-bold"
                              >Subtotal:
                              {{
                                tarea.precioContratista
                                  ? formatoAPrecio(
                                      setSubtotal(
                                        tarea.precioContratista,
                                        tarea.cantidad
                                      )
                                    )
                                  : "Por asignar"
                              }}</span
                            >
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col-12">
              <p class="my-0" v-if="esAdmin || esContratista">
                Aún no existen tareas registradas por supervisor.
              </p>

              <p class="my-0" v-if="esSupervisor">
                Aún no existen tareas registradas.
              </p>
            </div>
          </div>
        </section>
      </template>
    </div>

    <modal-crear-tarea
      :codigoTicket="this.id"
      @success="getTareas()"
    ></modal-crear-tarea>

    <modal-editar-tarea
      v-if="selectedEditData"
      :codigoTicket="this.id"
      @close="isEditingTarea = false"
      @success="
        isEditingTarea = false;
        init();
      "
      :data="selectedEditData"
      :active="isEditingTarea"
    ></modal-editar-tarea>

    <modal-eliminar-tarea
      :tareaId="tareaDeleteId"
      @success="getTareas()"
    ></modal-eliminar-tarea>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { precio } from "@/mixins/precio";
import regexMixin from "@/mixins/regexMixins";
import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";

import GetAllTareas from "@/apollo/queries/tareas/GetAllTareas.gql";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";

import ModalCrearTarea from "@/components/tareas/ModalCrearTarea";
import ModalEliminarTarea from "@/components/tareas/ModalEliminarTarea.vue";
import ModalEditarTarea from "@/components/tareas/ModalEditarTarea.vue";

export default {
  // props: {
  //   asignacion: {
  //     type: Object,
  //   },
  // },
  directives: { hideAdmin, hideContratante, hideSupervisor },
  components: {
    ModalCrearTarea,
    ModalEliminarTarea,
    ModalEditarTarea,
  },
  mixins: [precio, regexMixin],
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      tareas: [],
      asignacion: null,

      tareaDeleteId: null,
      isEditingTarea: false,
      selectedEditData: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    esAdmin: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    esSupervisor: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    esContratista: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 3;
    },
  },
  methods: {
    setSubtotal(precio, cantidad) {
      return parseFloat(precio) * parseInt(cantidad);
    },
    privateOpenEditTarea(tarea) {
      this.isEditingTarea = true;
      this.selectedEditData = tarea;
    },
    allowAddTareas: function() {
      let status = false,
        tipoUsuario = parseInt(this.datosUsuarioLogueado.tipoUsuario),
        estado = parseInt(this.asignacion.estado);

      if (tipoUsuario === 2 && estado == 3) {
        status = true;
      }

      return status;
    },

    init() {
      return new Promise((resolve) => {
        this.getInfo().then(() => {
          this.getTareas();

          resolve();
        });
      });
    },
    getInfo() {
      return new Promise((resolve) => {
        this.$apollo
          .query({
            query: GetAsignaciones,
            variables: {
              codigoTicket: this.id,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            this.asignacion = response.data.GetAsignaciones;

            resolve();
          });
      });
    },
    eliminarTareaRegistrada(tareaId) {
      this.tareaDeleteId = Number(tareaId);
      this.$bvModal.show("modal-eliminar-tarea");
    },
    getTareas() {
      this.loading = true;

      this.$apollo
        .query({
          query: GetAllTareas,
          variables: {
            numberPaginate: 100,
            page: 1,
            codigoTicket: this.id,
            estado: "",
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          this.tareas = res.data.GetAllTareas.data;

          this.tareas.forEach((item) => {
            item.copiaPrecioContratista = item.precioContratista;
            // item.precioAdministrador = item.SubCategoria.precioAdministrador;
          });

          this.loading = false;
        });
    },
  },
};
</script>
