<template>
  <b-modal
    id="modal-eliminar-tarea"
    hide-footer
    center
    :title="`Eliminar Partida # ${tareaId}`"
  >
    <p class="h4 text-center">
      ¿Esta seguro de que desea eliminar la partida <code>#{{ tareaId }}</code>?
    </p>
    <p class="h6 font-italic text-center mt-3">
      Para volver a tener esta partida deberás crearla nuevamente
    </p>
    <b-button
      :disabled="loadingDelete"
      @click="deleteTareaSupervisor"
      class="mt-3"
      block
      variant="danger"
    >
      <template v-if="loadingDelete">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Eliminando...</span>

        <span class="d-inline-block ml-2">Eliminando</span>
      </template>

      <span v-else>Borrar la partida #{{ tareaId }}</span>
    </b-button>
  </b-modal>
</template>

<script>
import DeleteTareaSupervisor from "@/apollo/mutations/tareas/DeleteTareasSupervisor.gql";

export default {
  props: {
    tareaId: {
      type: Number,
    },
  },
  data() {
    return {
      loadingDelete: false,
    };
  },
  methods: {
    deleteTareaSupervisor() {
      let variable = [];
      variable.push(this.tareaId);
      this.loadingDelete = true;
      this.$apollo
        .mutate({
          mutation: DeleteTareaSupervisor,
          variables: {
            input: variable,
          },
          errorPolicy: "all",
        })
        .then((res) => {
          this.loadingDelete = false;

          if (res.errors) {
            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` La partida no se ha podido eliminar, intentelo nuevamente `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Tarea no eliminada")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
          } else {
            this.$emit("success");
            this.$bvModal.hide("modal-eliminar-tarea");
            this.$toast.success(`Partida #${this.tareaId} Eliminada`, {
              // override the global option
              position: "top-right",
            });
          }
        });
    },
  },
};
</script>
